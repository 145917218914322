import React from "react";

const Header = () => {
    return (
        <header className="main-header">
        <nav className="navbar header-nav header-white header-transparent navbar-expand-lg one-page-nav">
            <div className="container">
                <a className="navbar-brand" href="">V Mampofu<span></span></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-collapse-toggle" aria-controls="navbar-collapse-toggle" aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbar-collapse-toggle">
                    <ul className="navbar-nav ml-auto">
                        <li><a className="nav-link" data-scroll-nav="0" href="#home"><span>Home</span></a></li>
                        <li><a className="nav-link" data-scroll-nav="1" href="#about"><span>About Me</span></a></li>
                        {/*<li><a className="nav-link" data-scroll-nav="2" href="#services"><span>Services</span></a></li>
                        <li><a className="nav-link" data-scroll-nav="3" href="#work"><span>Portfolio</span></a></li>
                        <li><a className="nav-link" data-scroll-nav="4" href="#blog"><span>Blog</span></a></li>*/}
                        <li><a className="nav-link" data-scroll-nav="5" href="#contactus"><span>Contact</span></a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    );
}

export default Header;