import React from "react";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 my-2">
                        <div className="nav justify-content-center justify-content-md-start">
                            <a target="_blank" href="https://www.facebook.com/vuyani.mampofu">
                                <i className="bi bi-facebook"></i>
                            </a>
                            <a target="_blank" href="https://twitter.com/jabu5468">
                                <i className="bi bi-twitter"></i>
                            </a>
                            <a target="_blank" href="https://www.instagram.com/vuyanimampofu/">
                                <i className="bi bi-instagram"></i>
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/in/vuyani-mampofu-06933824/">
                                <i className="bi bi-linkedin"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 my-2 text-center text-md-end">
                        <p>© 2022 copyright <a href="">V Mampofu</a> </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
