import React from "react";

const Contact = () => {
    return (
        <section id="contactus" data-scroll-index="5" className="section dark-bg-1 contactus-section">
            <div className="placeholder">
                <h5>Contact</h5>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center">
                        <div className="section-title">
                            <h2><span>Contact Me</span></h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12 my-12">
                        <div className="contact-info">
                            <div className="contact-name">
                                <div className="sm-title">
                                    <h3>Email</h3>
                                </div>
                                <p>alexis130@gmail.com</p>
                            </div>
                            {/*<div className="contact-name">
                                <div className="sm-title">
                                    <h3>Visit My Studio</h3>
                                </div>
                                <p>Warnwe Park Streetperrine, <br />FL 33157 New York City</p>
    </div>*/}
                            <div className="contact-name">
                                <div className="sm-title">
                                    <h3>Phone</h3>
                                </div>
                                <p>+27 62 4177 906</p>
                            </div>
                            <div className="social-share nav">
                                <a target="_blank" href="https://www.facebook.com/vuyani.mampofu">
                                    <i className="bi bi-facebook"></i>
                                </a>
                                <a target="_blank" href="https://twitter.com/jabu5468">
                                    <i className="bi bi-twitter"></i>
                                </a>
                                <a target="_blank" href="https://www.instagram.com/vuyanimampofu/">
                                    <i className="bi bi-instagram"></i>
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/in/vuyani-mampofu-06933824/">
                                    <i className="bi bi-linkedin"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-lg-8 my-3">
                        <div className="contact-form">
                            <div className="sm-title">
                                <h3>Get In Touch</h3>
                            </div>
                            <form id="contact-form" method="POST">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="Name" id="name" placeholder="Name *" className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="Email" id="email" placeholder="Email *" className="form-control" type="email" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input name="Subject" id="subject" placeholder="Subject *" className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" id="message" placeholder="Your message *" rows="3" className="form-control"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="send">
                                            <button className="px-btn px-btn-white" onClick={(e) => {console.log('Clicked...')}} type="button" value="Send"><span>Contact Us</span></button>
                                        </div>
                                        <span id="suce_message" className="text-success" style={{"display": "none"}}>Message Sent Successfully</span>
                                        <span id="err_message" className="text-danger" style={{"display": "none"}}>Message Sending Failed</span>
                                    </div>
                                </div>
                            </form>
                        </div>
</div>*/}
                    {/*<div className="col-12 mt-4">
                        <div className="p-3 dark-bg shadow grayscale">
                            <div className="ratio ratio-21x9">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3151.840107317064!2d144.955925!3d-37.817214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1520156366883" allowFullScreen=""></iframe>
                            </div>
                        </div>
</div>*/}
                </div>
            </div>
        </section>
    );
};

export default Contact;