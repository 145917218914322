import React from 'react';
import AboutUs from './AboutUs';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';
import Home from './Home';

const App = () => {
    return (
        <React.Fragment>
            <div id="loading">
                <div className="load-circle"><span className="one"></span></div>
            </div>
            <Header />
            <main>
                <Home />
                <AboutUs />
                <Contact />
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default App;