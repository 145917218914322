import React from "react";

const AboutUs = () => {
    return (
        <section id="about" data-scroll-index="1" className="section about-section dark-bg-1">
            <div className="placeholder">
                <h5>About</h5>
            </div>
            <div className="container">
                <div className="row align-items-center flex-row-reverse">
                    <div className="col-lg-7 ps-xl-5">
                        <div className="about-row about-text">
                            <div className="sm-title">
                                <h3>About Me</h3>
                            </div>
                            <h6>Hello, my name is <span>Vuyani</span> and i am a <span>front-end developer</span>,<span>back-end developer<span> and</span> software architect.</span></h6>
                            <p>I architect and build sites with amazing looks and fast loading times and most importantly gets the job done. Whatever you can think of, talk to me and I will get it done. When I'm done, you will have a site that gets the job done.</p>
                        </div>
                        <div className="about-row tag-text">
                            <div className="sm-title">
                                <h3>Front-end Tools</h3>
                            </div>
                            <div className="nav">
                                <span>Javascript</span>
                                <span>React </span>
                                <span>Angular </span>
                                <span>HTML </span>
                            </div>
                        </div>
                        <div className="about-row tag-text">
                            <div className="sm-title">
                                <h3>Backend Tools</h3>
                            </div>
                            <div className="nav">
                                <span>.NET Core</span>
                                <span>.NET Framework</span>
                                <span>Microsoft SQL Server</span>
                                <span>MongoDB</span>
                                <span>Azure Cosmos DB</span>
                                <span>Umbraco</span>
                                <span>Azure Storage</span>
                            </div>
                        </div>
                        <div className="about-row tag-text">
                            <div className="sm-title">
                                <h3>General Tools</h3>
                            </div>
                            <div className="nav">
                                <span>Azure DevOps</span>
                                <span>IIS</span>
                                <span>Git</span>
                                <span>VS Code</span>
                                <span>Visual Studio</span>
                                <span>Netlify</span>
                                <span>Azure App Service</span>
                            </div>
                        </div>
                        <div className="about-row progress-text">
                            <div className="sm-title">
                                <h3>Work Process</h3>
                            </div>
                            <div className="row g-1">
                                <div className="col-6 col-sm-3">
                                    <div className="d-flex align-items-center">
                                        <span>#01</span>
                                        <label className="col ps-2">Research</label>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3">
                                    <div className="d-flex align-items-center">
                                        <span>#02</span>
                                        <label className="col ps-2">Architect</label>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3">
                                    <div className="d-flex align-items-center">
                                        <span>#03</span>
                                        <label className="col ps-2">Development</label>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3">
                                    <div className="d-flex align-items-center">
                                        <span>#04</span>
                                        <label className="col ps-2">Launch</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="about-left">
                            <img src="vendor/img/vuyani_profile_2.jpg" title="" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;